import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, MenuItem, Select, ListItemText, Grid2, Tooltip } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { collection, getDocs, query, where, and } from 'firebase/firestore';
import { firestore } from '../firebase.js';
import dayjs from 'dayjs';
import Theme from "../components/theme";
import { ResourceCard } from "../components/ResourceCard";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//import LoadingIcon from "../components/LoadingIcon";


function ResourcesPage(props) {
  const selectedType = useRef("Any");
  const selectedDay = useRef("All");
  const selectedTime = useRef(null);
  const initResources = useRef(props.resources);
  const textType = useRef("any");
  const textTime = useRef(null);
  const textDay = useRef("all");
  const [stateChanges, setStateChanges] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const [notTimeFilteredResources, setNotTimeFilteredResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState(props.resources);

  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);




  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "All"];
  const resourceTypes = ["Food Pantry", "Shelter", "Medical", "Financial", "Other", "Any"];

  useEffect(() => {
    setFilteredResources(props.resources);
    initResources.current = props.resources;
    textTime.current = null;
    textType.current = "any";
    textDay.current = "all";
  }, [props.resources]);

  useEffect(() => { }, [stateChanges]);

  useEffect(() => {


    const getFilteredResourcesByDayAndType = async (day, type) => {
      const q = query(collection(firestore, "diffTimeResources"), and(where("type", "==", type), where(`openTimes.${day}`, "!=", [])));
      let querySnapshot = await getDocs(q);
      querySnapshot = convertDocToUseable(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setNotTimeFilteredResources(querySnapshot);
    }

    const getFilteredResourcesByDay = async (day) => {
      //console.log(`openTimes.${day}`);
      const q = query(collection(firestore, "diffTimeResources"), where(`openTimes.${day}`, "!=", []));
      try {
        const querySnapshot = await getDocs(q);
        let querySnapshot1 = convertDocToUseable(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        console.log(querySnapshot1)
        setNotTimeFilteredResources(querySnapshot1);
      }
      catch (error) {
        console.log(error);
      }

    }

    const getFilteredResourcesByType = async (type) => {
      //console.log(type);
      const q = query(collection(firestore, "diffTimeResources"), where("type", "==", type));
      let querySnapshot = await getDocs(q);
      querySnapshot = convertDocToUseable(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setNotTimeFilteredResources(querySnapshot);
    }
    if (selectedType.current === "Any" && selectedDay.current === "All") {
      if (initResources !== undefined) {
        setFilteredResources(initResources.current);
        setNotTimeFilteredResources(initResources.current);
      }
      setIsFiltered(false);
      textTime.current = null;
      textType.current = "any";
      textDay.current = "all";
    }
    else if (selectedType.current === "Any") {
      console.log("filtering by day");
      getFilteredResourcesByDay(selectedDay.current);
    }
    else if (selectedDay.current === "All") {
      console.log("filtring by type");
      getFilteredResourcesByType(selectedType.current);
    }
    else if (selectedDay.current !== "All" && selectedType.current !== "Any") {
      console.log("filtering by both");
      getFilteredResourcesByDayAndType(selectedDay.current, selectedType.current);
    }

  }, [isFiltered]);


  useEffect(() => {
    setIsFiltered(false);
  }, [stateChanges]);

  useEffect(() => {
    //console.log(notTimeFilteredResources);
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    console.log("In time filtering")
    let filtered = JSON.parse(JSON.stringify(notTimeFilteredResources));
    if (!isFiltered) {
      return;
    }
    if (selectedTime.current === null || selectedDay.current === "All" || !selectedTime.current.isValid()) {
      console.log("Time is null")
      setFilteredResources(filtered);
    }
    else {
      console.warn("Time is NOT null")
      console.log(selectedTime.current)
      const time = dayjs(`2025-01-${days.indexOf(selectedDay.current) + 5}T${selectedTime.current.format("HH:mm:ss")}`)
      console.log(time)
      console.log(notTimeFilteredResources)
      let discardIndices = [];
      for (let j = 0; j < notTimeFilteredResources.length; j++) {
        let discardResource = true;
        for (let i = 0; i < notTimeFilteredResources[j].openTimes[selectedDay.current].length / 2; i += 2) {
          console.log(dayjs(notTimeFilteredResources[j].openTimes[selectedDay.current][i]), dayjs(notTimeFilteredResources[j].openTimes[selectedDay.current][i + 1]))
          if (time.isBetween(dayjs(notTimeFilteredResources[j].openTimes[selectedDay.current][i]), dayjs(notTimeFilteredResources[j].openTimes[selectedDay.current][i + 1]))) {
            discardResource = false;
          }
        }
        if (discardResource) {
          discardIndices.push(j);
        }
      }
      discardIndices.sort((a, b) => b - a);
      for (let i = 0; i < discardIndices.length; i++) {
        filtered.splice(discardIndices[i], 1);
      }
      console.log(filtered);
      console.log("Finished time filtering")
      setFilteredResources(filtered);
    }
  }, [notTimeFilteredResources, isFiltered])

  const convertDocToUseable = (data) => {
    let dataCopy = data
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    for (let i = 0; i < 7; i++) {
      for (const key in data.openTimes) {
        if (daysOfWeek[i] === key) {
          //console.log(data.openTimes[key])
          dataCopy.openTimes[key] = data.openTimes[key].map((e) => dayjs(e))
        }
      }
    }
    return dataCopy;
  }




  return (
    <>
      <Helmet>
        <title>Homeless Resources - Food, Shelter, Medical & Financial Help</title>
        <meta name="description" content="Find essential resources including food pantries, shelters, and other services available near you." />
      </Helmet>
      <Theme>
        <Box textAlign="center" width="100%">
          <Typography color="primary" variant="h3">Resources</Typography>
          <Typography color="primary" variant="h5" padding="3vh">Click on any of the resources below for more info (You need to pick a day to filter by time)</Typography>

          <Grid2 container spacing={10} justifyContent="center">
            <Box display="inline" width="10%" justifyContent="flex-start">
              <Typography align="left">Resource Type</Typography>
              <Select
                defaultValue=""
                value={selectedType.current}
                onChange={(event) => { selectedType.current = event.target.value; setStateChanges(currValue => currValue + 1) }}
                sx={{ width: '100%', mb: 2 }}
              >
                {resourceTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="inline" width="10%">
              <Typography align="left">Day of Week</Typography>
              <Select
                defaultValue=""
                value={selectedDay.current}
                onChange={(event) => { selectedDay.current = event.target.value; setStateChanges(currValue => currValue + 1) }}
                sx={{ width: '100%', mb: 2 }}
              >
                {days.map((day) => (
                  <MenuItem key={day} value={day}>
                    <ListItemText primary={day} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="inline" width="10%">
              <Typography align="left">Time</Typography>
              <Tooltip title={(selectedDay.current.toLowerCase() === "all") ? "Pick a day to filter by time." : ""}>
                <span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label=" "
                      value={selectedTime.current}
                      defaultValue={null}
                      onChange={(value) => { selectedTime.current = value; setStateChanges(currValue => currValue + 1) }}
                      disabled={selectedDay.current === "All"}
                      fullWidth
                    />
                  </LocalizationProvider>
                </span>
              </Tooltip>
            </Box>
            <Button onClick={() => {
              setIsFiltered(false);
              selectedTime.current = null;
              selectedDay.current = "All";
              if (initResources !== undefined) {
                setFilteredResources(initResources.current);
              }
              selectedType.current = "Any";
              textDay.current = "All";
              textType.current = "Any";
              textTime.current = null;
              setStateChanges(currValue => currValue + 1);
            }}>
              Clear Filter
            </Button>
            <Button onClick={() => {
              setIsFiltered(true);
              textTime.current = selectedTime.current;
              textType.current = selectedType.current;
              textDay.current = selectedDay.current;
            }}>
              Filter
            </Button>
          </Grid2>


          {(textType.current.toLowerCase() === "any" && textDay.current.toLowerCase() === "all") ?
            <Typography sx={{ fontSize: "1.2rem" }}>No filter applied.</Typography> :
            <Typography sx={{ fontSize: "1.2rem" }}>Filtering for {<strong>{textType.current.toLowerCase() === "any" ? "all resource types" : `${textType.current} type resources`}</strong>} open on <strong>{textDay.current.toLowerCase() === "all" ? "any day" : textDay.current}</strong> at<strong>{(textTime.current !== null && textTime.current.isValid()) ? ` ${textTime.current.format("hh:mm a")}.` : " any time."}</strong></Typography>
          }

          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", gap: "2vw", marginX: "5vw" }}>
            {filteredResources.length !== 0 ? (
              filteredResources.map((location) => (
                <ResourceCard key={location.id} location={location} />
              ))
            ) : (
              <Typography variant="h5" sx={{ mt: "50px" }}>No Available Resources</Typography>
            )}
          </Box>
        </Box>



      </Theme>

    </>
  );
}

export default ResourcesPage;
