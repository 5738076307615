import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, Checkbox, FormControlLabel, InputAdornment, Grid2 } from '@mui/material';
import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import { firestore } from '../firebase';

const ResourceForm = () => {
  const [mode, setMode] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [resources, setResources] = useState([]);
  const [closed, setClosed] = useState(Array(7).fill(true));
  const [numSlots, setNumSlots] = useState(Array(7).fill(0));
  const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
  dayjs.extend(isSameOrBefore);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    openTimes: {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: []
    },
    overview: '',
    services: '',
    type: '',
    email: '',
    phone: '',
    website: '',
  });

  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const types = ["Food Pantry", "Shelter", "Medical", "Financial"];

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'diffTimeResources'));
        const resourceList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourceList);
      } catch (error) {
        console.error("Error fetching resources: ", error);
      }
    };

    fetchResources();
  }, []);


  useEffect(() => {
    let isClosed = Array(7).fill(false);
    let i = 0;
    for (const key in formData.openTimes) {
      if (formData.openTimes[key].length === 0) {
        isClosed[i] = true;
      }
      i += 1;
    }
    setClosed(isClosed);
  }, [formData.openTimes]);




  useEffect(() => {
    const fetchResource = async () => {
      if (mode === 'edit' && resourceId) {
        try {
          const resourceRef = doc(firestore, 'diffTimeResources', resourceId);
          const resourceSnap = await getDoc(resourceRef);

          if (resourceSnap.exists()) {
            let data = resourceSnap.data();
            data.openTimes = convertDocToUseable(data);
            console.log(data)
            setFormData(data);

          } else {
            alert("No such resource found!");
          }
        } catch (error) {
          console.error("Error fetching resource: ", error);
        }
      }
    };

    fetchResource();
  }, [mode, resourceId]);

  const convertDocToUseable = (data) => {
    let times = {}
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    for(let i = 0; i < 7; i++) {
      for(const key in data.openTimes) {
        if(daysOfWeek[i] === key) {
          console.log(data.openTimes[key])
          times[key] = data.openTimes[key].map((e) => dayjs(e))
        }
      }
    }
    return times;    
  }

  

  const handleModeChange = (event) => {
    setMode(event.target.value);
    setFormData({
      name: '',
      address: '',
      openTimes: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: []
      },
      overview: '',
      services: '',
      type: '',
      email: '',
      phone: '',
      website: '',
    });
    setClosed(Array(7).fill(true));
    setResourceId('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleOpenTimesChange = (key, index, i, startOrEnd, curDay) => {
    console.warn("Curday", curDay);
    setFormData((prevData) => {
      let updatedTimes = {}

      for (const day in formData.openTimes) {
        updatedTimes[day] = formData.openTimes[day].map(e => e)
      }
      if (startOrEnd === "delete") {
        updatedTimes[key].splice(i, 2);
      } else {

        if (startOrEnd === "start") {
          updatedTimes[key][i] = curDay;
          console.warn("Start", curDay)
          let [start, end] = [curDay || dayjs(`2025-01-${5 + index}T00:00:00`), updatedTimes[key][i + 1] || dayjs(`2025-01-${5 + index}T00:00:00`)]
          if (end.isSameOrBefore(start)) {
            updatedTimes[key][i + 1] = dayjs(`2025-01-${String(index + 6).padStart(2, '0')}T${end.format("HH:mm:ss")}`);
            console.warn("End",  updatedTimes[key][i + 1])
          } else {
            updatedTimes[key][i + 1] = dayjs(`2025-01-${String(index + 5).padStart(2, '0')}T${end.format("HH:mm:ss")}`);
            console.warn("End",  updatedTimes[key][i + 1])
          }
        }
        else {
          console.warn(dayjs())
          console.warn("Start", updatedTimes[key][i])
          let [start, end] = [updatedTimes[key][i] || dayjs(`2025-01-${5 + index}T00:00:00`), curDay || dayjs(`2025-01-${5 + index}T00:00:00`)]
          if (end.isSameOrBefore(start)) {
            updatedTimes[key][i + 1] = dayjs(`2025-01-${String(index + 6).padStart(2, '0')}T${curDay.format("HH:mm:ss")}`);
            console.warn("End",  updatedTimes[key][i + 1])

          } else {
            updatedTimes[key][i + 1] = dayjs(`2025-01-${String(index + 5).padStart(2, '0')}T${curDay.format("HH:mm:ss")}`);
            console.warn("End",  updatedTimes[key][i + 1])

          }
        }
      }
      console.log(updatedTimes);
      return { ...prevData, openTimes: updatedTimes };
      
    });
    console.log(formData);
  };

  const handleNumSlotsChange = (key, index, addOrRemove) => {
    if(addOrRemove === "add") {
    setNumSlots(numSlots.map((e, i) => { return (i === index) ? e + 1 : e }));

    let openTimesTemp = {}

    for (const day in formData.openTimes) {
      openTimesTemp[day] = formData.openTimes[day].map((e) => dayjs(e));
    }
    console.log("before parsing", formData.openTimes);
    let data = JSON.parse(JSON.stringify(formData));
    console.log("after parsing", formData.openTimes);
    // Ensure openTimes[key][index] exists as an array
    openTimesTemp[key].push(dayjs(`2025-01-${5 + index}T00:00:00`), dayjs(`2025-01-${5 + index}T00:00:00`));
    data.openTimes = openTimesTemp;
    setFormData(data);
  }
  else {
    setNumSlots(numSlots.map((e, i) => {return (i === index) ? e - 1 : e}))
  }
  }

  const handleClosedChange = (index, isClosed) => {
    setClosed(closed.map((e, i) => { return i === index ? isClosed : e }));
    //let updatedOpenTimes = [...formData.openTimes];
    //updatedOpenTimes[index] = isClosed ? "Closed" : updatedOpenTimes[index];
    //setFormData((prevData) => ({...prevData, openTimes: updatedOpenTimes}));
  };

  const camelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => index === 0 ? match.toLowerCase() : match.toUpperCase())
      .replace(/\s+/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    const dataToSave = formData;

    let i = 0;
    for(const key in dataToSave.openTimes) {  
      if(closed[i]) {
        dataToSave.openTimes[key] = [];
        i++;
        continue;
      }
      dataToSave.openTimes[key] = dataToSave.openTimes[key].map((time) => {console.log(time); return time.toISOString()});
      i++;
    }
    console.warn(dataToSave.openTimes);

    const resourceDocId = camelCase(formData.name); // Use the camel case resource name as the ID

    try {
      if (mode === 'edit') {
        await setDoc(doc(firestore, "diffTimeResources", resourceId), dataToSave);
        alert("Resource updated successfully!");
      } else {
        await setDoc(doc(firestore, "diffTimeResources", resourceDocId), dataToSave); // Use the camel case ID
        alert("Resource added successfully!");
      }

      setFormData({
        name: '',
        address: '',
        openTimes: {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: []
        },
        overview: '',
        services: '',
        type: '',
        email: '',
        phone: '',
        website: '',

      });
      setClosed(Array(7).fill(true));
      setMode('');
      setResourceId('');
    } catch (error) {
      console.error("Error saving resource: ", error);
      alert("Failed to save resource. Please try again.");
    }
  };
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      try {
        await deleteDoc(doc(firestore, 'diffTimeResources', resourceId));
        alert("Resource deleted successfully!");
        setFormData({
          name: '',
          address: '',
          openTimes: {
            Sunday: [],
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: []
          },
          overview: '',
          services: '',
          type: '',
          email: '',
          phone: '',
          website: '',
        });
        setMode('');
        setResourceId('');
      } catch (error) {
        console.error("Error deleting resource: ", error);
        alert("Failed to delete resource. Please try again.");
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Resource Management
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please select whether you'd like to add a new resource or edit an existing one.
        </Typography>

        <FormControl fullWidth margin="normal">
          <Select
            value={mode}
            onChange={handleModeChange}
            displayEmpty
            renderValue={() => {
              if (mode === "") {
                return <Typography>Select Mode</Typography>;
              }
              if (mode === 'add') {
                return <Typography>Add New Resource</Typography>
              }
              return <Typography>Edit Existing Resource</Typography>
            }}
          >
            <MenuItem value="add">Add New Resource</MenuItem>
            <MenuItem value="edit">Edit Existing Resource</MenuItem>
          </Select>
        </FormControl>

        {mode === 'edit' && (
          <FormControl fullWidth margin="normal">
            <Select
              value={resourceId}
              onChange={(e) => setResourceId(e.target.value)}
              disabled={!resources.length}
              displayEmpty
              renderValue={() => {
                if (resourceId === '') {
                  return <Typography>Select Resource to Edit</Typography>;
                }
                return formData.name;
              }}
            >
              {resources.map((resource) => (
                <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {(mode === "add" || (mode === "edit" && resourceId !== "")) && (
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              label="Resource Name"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={`${formData.name.length}/50 characters`}
              required
            />
            <TextField
              name="address"
              value={formData.address}
              onChange={handleChange}
              label="Address"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.address.length}/100 characters`}
              required
            />
            <TextField
              label="Phone"
              value={formData.phone}
              onChange={(e) => {
                const phoneValue = e.target.value;
                setFormData({ ...formData, phone: phoneValue });
                if (phoneValue === '') {
                  setPhoneError(''); // Clear error if empty
                } else {
                  const phoneRegex = /^[0-9]+$/;
                  if (!phoneRegex.test(phoneValue)) {
                    setPhoneError('Invalid phone number format');
                  } else {
                    setPhoneError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 15,
                  },
                },
              }}
              helperText={phoneError || `${formData.phone.length}/15 characters`}
              error={!!phoneError}
            />
            <TextField
              label="Email"
              value={formData.email}
              onChange={(e) => {
                const emailValue = e.target.value;
                setFormData({ ...formData, email: emailValue });
                if (emailValue === '') {
                  setEmailError('');
                } else {
                  // eslint-disable-next-line no-useless-escape
                  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (!emailRegex.test(emailValue)) {
                    setEmailError('Invalid email format');
                  } else {
                    setEmailError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={emailError || `${formData.email.length}/100 characters`}
              error={!!emailError}
            />
            <TextField
              name="website"
              value={formData.website}
              onChange={handleChange}
              label="Website Link"
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.website.length}/100 characters`}
            />
            <TextField
              name="overview"
              value={formData.overview}
              onChange={handleChange}
              label="Overview"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              }}
              helperText={`${formData.overview.length}/500 characters`}
              required
            />
            <TextField
              name="services"
              value={formData.services}
              onChange={handleChange}
              label="Services Offered"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              }}
              helperText={`${formData.services.length}/500 characters`}
              required
            />

            <FormControl fullWidth margin="normal">
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                label="type"
                required
                displayEmpty
                renderValue={() => {
                  if (formData.type === "") {
                    return <Typography>Resource Type</Typography>;
                  }
                  return <Typography>{formData.type}</Typography>;
                }}
              >
                {types.map((type, index) => (
                  <MenuItem key={index} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {Object.entries(formData.openTimes).map(([key, values], index) => (
              <Box key={index} sx={{ gap: 2, alignItems: 'center' }}>
                <Typography>{key}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      label={index}
                      checked={closed[index]}
                      onChange={(e) => handleClosedChange(index, e.target.checked)}
                    />
                  }
                  label="Closed"
                />
                <Button onClick={() => handleNumSlotsChange(key, index, "add")}>Add Time Slot</Button>
                {values.map((e, i) => {
                  if (i % 2 === 0)
                    return (
                      <Grid2 display="flex" label={i}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Start Time"
                            value={(closed[index] !== null && closed[index]) ? null : values[i]}
                            onChange={(value) => handleOpenTimesChange(key, index, i, 'start', value)}
                            disabled={closed[index]}
                            required
                            fullWidth
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label={'End Time'}
                            value={(closed[index] !== null && closed[index]) ? null : values[i + 1]}
                            onChange={(value) => handleOpenTimesChange(key, index, i, 'end', value)}
                            disabled={closed[index]}
                            required
                            fullWidth
                          />
                        </LocalizationProvider>
                        <Button onClick={() => {handleOpenTimesChange(key, index, i, 'delete', 0); handleNumSlotsChange(key, index, "delete")}}>Delete Slot</Button>
                      </Grid2>)
                      else return null
                }
                )}
              </Box>
            ))}
            <Button type="submit" variant="contained">
              {mode === 'edit' ? "Save Changes" : "Add Resource"}
            </Button>


            {/* Show Delete Button only if mode is "edit" and a resource is selected */}
            {mode === 'edit' && resourceId && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleDelete}
                sx={{ marginTop: 2 }}
              >
                Delete Resource
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResourceForm;
