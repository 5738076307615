import React from 'react';
import { Box, Button, Typography } from '@mui/material';
//import lthcLogo from "../images/lthcLogo.png";
import '@fontsource/raleway';
import '@fontsource/oxygen';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function Footer() {
  return (
    <Box sx={{ bgcolor: '#016c9d', width: '100%', position: 'sticky', bottom: 0, mt: isMobile ? 0 : "5vh" }}>
      <hr />
      <Box 
        sx={{ 
          p: 2, 
          height: isMobile ? 'auto' : 'auto', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          flexDirection: isMobile ? 'row' : 'row',
          gap: isMobile ? 2 : 0 
        }}
      >
        {/* Contact Section - left side */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, ml: isMobile ? "10px" : "40px", textAlign: 'left', width: isMobile ? '50%' : 'auto' }}>
          {/*<img src={lthcLogo} height={isMobile ? 0 : 0} alt="LTHC logo" />*/}
          <Typography fontFamily="Oxygen" fontWeight="bold" fontSize={20} sx={{color:"white"}}>Contact</Typography>
          <Typography fontFamily="Oxygen" fontSize={isMobile ? 13 : 15} sx={{color:"white"}}>Contact the team at info@LTHC.net</Typography>
        </Box>
        
        {/* Get Connected Section - right side */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', textAlign: 'right', mr: isMobile ? "10px" : "40px", width: isMobile ? '50%' : 'auto' }}>
          <Typography fontFamily="Oxygen" fontWeight="bold" fontSize={20} sx={{color:"white"}}>Get Connected!</Typography>
          <Typography fontFamily="Oxygen" fontSize={isMobile ? 13 : 15} sx={{color:"white"}}>
            Please visit the LTHC Homepage or contact the center at info@LTHC.net
          </Typography>
        </Box>
      </Box>



      {/* Bottom Buttons */}
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: isMobile ? 2 : 4, flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button component={Link} to="/" disableRipple sx={{ textTransform: 'none', fontFamily: 'Oxygen', fontSize: isMobile ? 13 : 15, color: 'white' }}>Home</Button>
        <Button component={Link} to="/resourcestab/resources" disableRipple sx={{ textTransform: 'none', fontFamily: 'Oxygen', fontSize: isMobile ? 13 : 15, color: 'white' }}>Resources</Button>
        <Button component="a" href="https://lthc.net/" disableRipple sx={{ textTransform: 'none', fontFamily: 'Oxygen', fontSize: isMobile ? 13 : 15, color: 'white' }}>LTHC Website</Button>
        <Button component={Link} to="/login" disableRipple sx={{ textTransform: 'none', fontFamily: 'Oxygen', fontSize: isMobile ? 13 : 15, color: 'white' }}>Admin Login</Button>
      </Box>
    </Box>
  );
}

export default Footer;

