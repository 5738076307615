import LocalDiningOutlinedIcon from "@mui/icons-material/LocalDiningOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import React, {useEffect, useState} from "react";
// import {getDistance} from "../utils/distance";
import OpenClosed from "./OpenClosed";
import { Link } from "react-router-dom" ;

export function ResourceCard({location, dayFilterOn}) {

    // const [distance, setDistance] = useState(null);

    function getIcon(type) {
        const fontSize = 28;
        if(type === "Food Pantry") {
            return <LocalDiningOutlinedIcon sx={{ fontSize }} />
        } else if(type === "Shelter") {
            return <HomeOutlinedIcon sx={{ fontSize }} />
        } else if(type === "Medical") {
            return <MedicalServicesOutlinedIcon sx={{fontSize}}/>
        } else if(type === "Financial") {
            return <MonetizationOnOutlinedIcon sx={{fontSize}}/>
        } else {
            return null;
        }
    }
    // useEffect(() => {
    //     const fetchDistance = async () => {
    //         try {
    //             const result = await getDistance(location.address);
    //             if (result) {
    //                 setDistance(result);
    //             }
    //         } catch (error) {
    //             console.error("Failed to fetch distance:", error);
    //         }
    //     };
    //     fetchDistance();
    // }, [location.address]);

    return (
        <div className="flex flex-col w-[300px] bg-white p-4 rounded-xl shadow-md border-2 border-gray-600">
        <div className="relative w-full flex justify-center items-start">
            <div className="absolute left-0 top-0">{getIcon(location.type)}</div>
            <h3 className="text-xl font-bold text-dark_blue text-center pl-7">{location.name}</h3>
        </div>
            {/* {distance && (
                <p className="text-black mt-1">📍 {distance} miles away</p>
            )} */}
            <OpenClosed hours={location.openTimes} dayFilterOn={dayFilterOn} />
            
            <div className="flex flex-col justify-items-end justify-content-end mt-auto gap-4" >
                <button
                    className="bg-button_yellow text-black py-2 px-4 rounded-lg font-semibold hover:bg-opacity-60 shadow-md"
                    onClick={(e) => {
                    e.preventDefault();
                    window.open(
                        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.address)}`,
                        "_blank"
                    );
                    }}
            >
                Get Directions
                </button>

                <Link
                    to={`/resourcestab/${location.name}`}
                    className="bg-button_yellow text-black py-2 px-4 rounded-lg font-semibold hover:bg-opacity-60 flex items-center justify-center shadow-md">
                    View Details
                </Link>
            </div>
        </div>
    );
}
