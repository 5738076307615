import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Typography, Box } from "@mui/material";


export default function OpenClosed({ hours, dayFilterOn}) {
    const [showTimes, setShowTimes] = useState(false);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [sortedOpenTimes, setSortedOpenTimes] = useState({});
    const [todayHours, setTodayHours] = useState([]);
    const [status, setStatus] = useState();

    useEffect(() => {

        function sortOpenTimes(openTimes) {
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            let sortedOpenTimes = {};

            for (let i = 0; i < 7; i++) {
                for (const key in openTimes) {
                    if (daysOfWeek[i] === key) {
                        sortedOpenTimes[key] = openTimes[key].map((e) => dayjs(e))
                    }
                }
            }

            return sortedOpenTimes;
        }
        if(hours !== undefined) {
            const times = sortOpenTimes(hours);
            console.log("SortedOpenTimes", times);
            setSortedOpenTimes(times);
        }
    }, [hours])

    useEffect(() => {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        function getStatus() {

            const now = dayjs();
            const todayHours = sortedOpenTimes[daysOfWeek[now.day()]];
            //console.log("todayhours", sortedOpenTimes[daysOfWeek[now.day()]]);
            if (todayHours === "Closed") return { status: "closedToday" }
            if (todayHours.length === 0) return { status: "closedToday" };

            for (let i = 0; i < todayHours.length; i += 2) {
                if (now.isBetween(todayHours[i], todayHours[i + 1])) {
                    return { status: "openNow", hours: todayHours };
                }
            }
            return { status: "closedNow", hours: todayHours };
        }
        if(Object.keys(sortedOpenTimes) === 0 ) {
            const { status, hours: todayHours } = getStatus();
            setStatus(status);
            setTodayHours(todayHours);
        }
    }, [sortedOpenTimes])

    useEffect(() => { }, [todayHours, status]);

    const isBetween = require("dayjs/plugin/isBetween");
    dayjs.extend(isBetween);




    return (
        <div>
            <div
                className="flex flex-row gap-2 items-center cursor-pointer transition-all duration-300 p-2 rounded-lg"
                onClick={() => setShowTimes(!showTimes)}
            >
                {dayFilterOn ? (
                    status === "openNow" ? (
                        <div className="flex flex-row items-center gap-2 text-lime-800">
                            <CheckCircleOutlineIcon />
                            <p>Open now ({todayHours})</p>
                        </div>
                    ) : status === "closedNow" ? (
                        <div className="flex flex-row items-center gap-2 text-yellow-600">
                            <BlockIcon />
                            <p>Closed now (Opens: {todayHours})</p>
                        </div>
                    ) : (
                        <div className="flex flex-row items-center gap-2 text-red-500">
                            <BlockIcon />
                            <p>Closed today</p>
                        </div>
                    )
                ) : 
                    <div className="flex flex-row items-center gap-2">
                        <AccessTimeIcon />
                        <p>See times</p>
                    </div>
                }

               <button className="flex items-center px-1 py-1 bg-button_grey text-black rounded-full shadow-md hover:bg-hover_button_grey transition">
                    <KeyboardArrowDownIcon
                    className={`w-2 h-2 transform transition-transform duration-300 ${showTimes ? "rotate-180" : "rotate-0"}`}
                    />
                </button>
            </div>

            <Box
                className={`flex flex-col gap-1 text-left p-2 ml-3 mt-2 transition-all duration-500 overflow-hidden ${showTimes ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
                    }`}
            >
                {Object.entries(sortedOpenTimes).map(([day, timeArray], index) => {
                    if (timeArray.length === 0) {
                        return (
                            <Box>
                            <Typography>{daysOfWeek[index]}</Typography>
                            <Typography>{"Closed"}</Typography>
                            </Box>
                        )
                    }
                    else
                        return (
                            <Box>
                                <Typography>{daysOfWeek[index]}</Typography>
                                {timeArray.map((time, i) => {
                                    if (i % 2 === 0)
                                        return (

                                            <p key={index + i} className="text-sm transition-opacity duration-500">
                                                <span key={index + i * 2} className="font-semibold">{"   "}</span> {`${timeArray[i].format("hh:mm A")} - ${timeArray[i + 1].format("hh:mm A")}`}
                                            </p>)
                                    else
                                            return null;
                                })}
                            </Box>
                        )
                })
                }
            </Box>
        </div>
    );
}
