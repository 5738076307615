import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Link} from "react-router-dom";

export default function ServiceCard({icon: Icon, name, desc, link}) {
    return (
        <div className="flex flex-col shadow-md p-5 rounded-lg w-full sm:w-fit text-[#2e5c83] gap-3 max-w-[300px]">
            {Icon && <Icon style={{fontSize: "50px"}} className="text-black"/>}
            <h3 className="text-lg font-bold">{name}</h3>
            <p className="flex-1">{desc}</p>
            <Link to={link}>
                <div className="flex flex-row gap-1 items-center font-semibold mt-auto">Learn more<ArrowForwardIcon/>
                </div>
            </Link>
        </div>

    )
}