import React from 'react';
import image from '../images/lthcHQ_Sunny.jpeg';
import { Helmet } from 'react-helmet-async';
import ServiceCard from "../components/ServiceCard";
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { FaTshirt } from "react-icons/fa";
import PaidIcon from '@mui/icons-material/Paid';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SchoolIcon from '@mui/icons-material/School';
import {Link} from "react-router-dom";


const HomePage = () => {
  
  return (
    <>
    
    <Helmet>
      <title>LTHC Homeless Services | Centralized Support & Resources</title>
      <meta name="description" content="LTHC Homeless Services provides centralized access to shelter, food, and other assistance. Find up-to-date information on availability and timings." />
      <meta name="keywords" content="LTHC, homeless services, shelter, food assistance, community support" />
    </Helmet>
        <div className="flex flex-col gap-10">
        <div
            className="relative w-full sm:min-h-[660px] flex items-center bg-cover bg-center sm:pl-[16%]"
            style={{
                backgroundImage: window.innerWidth >= 640 ? `url(${image})` : "none",
            }}
        >
            <div
                className="flex flex-col p-5 bg-gradient-to-b from-[#4eb0db] to-[#427a93] sm:bg-gray-800 sm:bg-opacity-50 sm:bg-none max-w-2xl gap-3">
                <h2 className="font-bold text-[32px] sm:text-[40px] text-white">
                    Connecting the Tippecanoe County Community to Vital Resources
                </h2>
                <p className="text-white">
                    Let us help you locate housing, food, counseling, and a path forward.
                </p>
                
                    <button
                        className="self-end mt-3 text-center px-6 py-4 bg-sky-300 text-black font-semibold w-fit rounded-full text-sm">
                            <Link to={"/resourcestab/resources"}>
                        Discover Resources
                        </Link>
                    </button>
                
            </div>
        </div>
            <div className="flex flex-col items-center mb-5">
                <h2 className="font-bold text-[32px] sm:text-[40px] text-[#2e5c83]">Services</h2>
            <p className="mb-5">Below are the services that we offer.</p>
            <div className="flex flex-wrap flex-row justify-center  gap-6 px-3">
                <ServiceCard desc="Find safe places to stay, including shelters and long-term housing options."
                             name="Housing" icon={LocalHotelIcon} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Get free meals, groceries, and food assistance from local pantries and kitchens."
                             name="Food" icon={RestaurantMenuIcon} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Find free access to essential clothing items like jackets, pants, shirts, or socks."
                             name="Clothing" icon={FaTshirt} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Get connected with physical health services, mental health services, and substance abuse services."
                             name="Health" icon={HealthAndSafetyIcon} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Get help with budgeting, financial assistance, and job support to stay on track."
                             name="Finances" icon={PaidIcon} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Find childcare, parenting support, and family services."
                             name="Family Resources" icon={FamilyRestroomIcon} link={"/resourcestab/resources"}/>
                <ServiceCard desc="Access free classes, job training, and tutoring to build new skills and oppurtunities."
                             name="Education" icon={SchoolIcon} link={"/resourcestab/resources"}/>
            </div>
        </div>
        </div>
    </>
  );
};

export default HomePage;
