import React, { useState, useEffect, useRef } from 'react';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WorldWebOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
//import { Box, Typography, Grid2, textFieldClasses, } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import formatPhone from "../utils/formatPhoneNumber";


function SpecificResourcePage(props) {
    //const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [goalHeight, setGoalHeight] = useState();
    const [bigger, setBigger] = useState();
    const [height, setHeight] = useState(0);
    const [test, setTest] = useState(true);
    const hours = useRef(null);
    const overviewBox = useRef(null);
    const map = useRef(null);
    const [mapWidth, setMapWidth] = useState(0);
    const [phone, setPhone] = useState();
    const [website, setWebsite] = useState();
    const [email, setEmail] = useState();
    const marginLen = isDesktop ? "75%" : "87%"



    useEffect(() => {
        if (map !== null && map.current !== null) {
            if (mapWidth === 0 && map.current) {
                setMapWidth(map.current.offsetWidth);
            }
            if (hours.current && overviewBox.current && height === 0) {
                setGoalHeight(hours.current.offsetHeight);
                setHeight(overviewBox.current.offsetHeight);
            }
            if (height < goalHeight) {
                setBigger(true);
            }
            setTest(false);

            window.addEventListener('resize', () => setMapWidth(map.current.offsetWidth));
        }
        setEmail(props.resource.email !== " " && props.resource.email !== "" && props.resource.email !== undefined);
        setPhone(props.resource.phone !== " " && props.resource.phone !== "" && props.resource.email !== undefined);
        setWebsite(props.resource.website !== " " && props.resource.website !== "" && props.resource.email !== undefined);
    }, [mapWidth, height, goalHeight, bigger, test, props.resource, phone, email, website]);

    return(
       <>
        <Helmet>
                <title>{props.resource.name} - LTHC Homeless Services</title>
                <meta name="description" content={`Find details about ${props.resource.name}, including services, location, and availability.`} />
                <meta name="keywords" content="homeless services, community aid, shelters, food assistance, LTHC resources" />
            </Helmet>
        <div className="flex flex-col items-center gap-100" >
            {/*Header*/}
            <div className="flex" style={{display: "flex",
                    background:"#016C9D",
                    width: "100%",
                    height: "200px",
                    //padding: "10px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "30px"
                    }}
            >
                <div className="font-bold text-[50px] sm:text-[64px] text-white justify-center" 
                style={{ justifyContent: 'center', textAlign: "center",}}>
                    {props.resource.name}
                    </div>
        </div>
        <div className="mb-5" style={{display: "flex", width: marginLen, height: "150%",}}>  
        </div>
        
        
        <div className='flex flex-col' style={{width: marginLen}}>
          
            <div className="font-bold text-[28px] sm:text-[36px]" 
                style={{ justifyContent: 'left',}}>
                    Resource Overview
                    </div>
            <div className="mb-5" style={{display: "flex",
                    width: "100%",
                    height: "100%",
                    }}>  
            </div>
            <div className="font text-[25px] sm:text-[28px]" 
                style={{ justifyContent: 'left',}}>
                    {props.resource.overview}
                    </div>
        </div>
        
        {/*Line break with margins */}
        <div className='flex my-5' style={{width: marginLen, height: "2px",background:"#000",}}>
        </div>
        

        <div className='flex flex-row' style={{width: marginLen}}>
        
        <div className='flex flex-col' style={{width: marginLen}}>
            
            <div className="font-bold text-[28px] sm:text-[36px] max-w-[520px] mb-5" 
              style={{ justifyContent: 'left',}}>
                  Hours of Operation
            </div>
            
                
            {/**Days and times */}
            <div className='flex flex-col max-w-[520px]' style={{width: "100%"}}>  
            <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Sunday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[6]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Monday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[0]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Tuesday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[1]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Wednesday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[2]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Thursday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[3]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Friday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[4]}
                    </p>
                </div>
                <div className='flex flex-row justify-between ' style={{width: "100%"}}>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        Saturday:
                    </p>
                    <p className="font text-[25px] sm:text-[28px] w-fit">
                        {props.resource.openTimes[5]}
                    </p>
                </div>
                
            </div>
        </div>
            <div className='relative max-[1440px]:hidden ml-2 mr-8 justify-center' style={{width: "2px", background:"#000",}}>
            </div>
            <div className='flex max-[1440px]:hidden w-3/4 '>
                <ContactInfo resource={props.resource}/>
            </div>
        </div>
        
        
        {/*Line break with margins */}
        <div className='relative my-5' style={{width: marginLen, height: "2px",background:"#000",}}>
        </div>

        {/**Contact info */}
        <div className='hidden max-[1440px]:flex' style={{width: marginLen}}>
            <ContactInfo resource={props.resource}/>
            
        </div>
        
        {/*Line break with margins */}
        <div className='hidden max-[1440px]:flex my-5' style={{width: marginLen, height: "2px",background:"#000",}}>
        </div>

        <div className='flex flex-col' style={{width: marginLen}}>
            <div className="font-bold text-[28px] sm:text-[36px]" 
                style={{ justifyContent: 'left',}}>
                    Location Map
                    </div>
                <div className="mb-5" style={{display: "flex",
                    width: "100%", height: "100%",}}>
                        
                    </div>
                    <iframe
                        style={{ position: "inline", padding: "2vh" }}
                        title="map"
                        width="96%"
                        height = {isDesktop ? "550" : "300"}
                        frameBorder="0"
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAZg61q1ejUnk97tQt6ZJcfkxoTmJ794As&q=${encodeURIComponent(props.resource.address)}`}
                        allowFullScreen>
                    </iframe>     

        </div>

        <div className="mb-5" style={{display: "flex", width: marginLen, height: "20px%",}}>  
        </div>

        
        </div>
    </>
        
    )
    }
function ContactInfo(props){
    return(
        
        <div className='flex flex-col w-full' >
            <div className="font-bold text-[28px] sm:text-[36px] mb-5" 
                style={{ justifyContent: 'left',}}>
                    Contact Information
            </div>
                
            
            <div className="font text-[25px] sm:text-[28px]" 
                >
                    {props.resource.phone===""? "":<PhoneOutlinedIcon style={{fontSize: "50px"}} className="text-black"/>}
                    {props.resource.phone===""? "":" "+formatPhone(props.resource.phone)}
            </div>
            <div className="font text-[25px] sm:text-[28px]" 
                style={{ justifyContent: 'left',}}>
                    {props.resource.email===""? "":<EmailOutlinedIcon style={{fontSize: "50px"}} className="text-black"/> }
                    {" "}
                    <a href={`mailto:${props.resource.email}`} >{props.resource.email}</a>
            </div>
            <div className="text-blue text-[25px] sm:text-[28px] " 
                style={{ justifyContent: 'left'}}>
                    {props.resource.website===""? "":<WorldWebOutlinedIcon style={{fontSize: "50px"}} className="text-black"/> }
                    {" "}
                    {props.resource.website===""? "":<u>
                    <a href={props.resource.website} target="_blank" rel="noreferrer" style={{color:"blue"}}>
                    {props.resource.name+" Website"}
                    </a></u>}

                    {/*<Typography fontSize="28px" position="inline" paddingBottom="3vh" paddingLeft="1vh" paddingRight="2.5vh" component="a" href={`https://www.${props.resource.website}`}>
                        {props.resource.website}</Typography>*/}
            </div>
            
            <div className="font text-[25px] sm:text-[28px]" 
                style={{ justifyContent: 'left',}}>
                    {props.resource.address===""? "":<LocationOnOutlinedIcon style={{fontSize: "50px"}} className="text-black"/>}
                    {" "}
                    {props.resource.address===""? "":<u>
                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(props.resource.address)}&travelmode=transit`} target="_blank" rel="noreferrer" style={{color:"blue"}}>
                    {props.resource.address}
                    </a></u>}
            </div>
            
        </div>
    )
}
export default SpecificResourcePage;
